.download-button {
    display: flex;
    align-items: center;
}

.download-button img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.download-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download-modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.download-modal .spinner {
    width: 40px;
    height: 40px;
    border-width: 5px;
}

.download-error {
    font-size: 18px;
    color: var(--error-text);
}
