.featured-laws h3 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
}

.featured-laws table {
    border-spacing: 0px 17px;
    border-collapse: separate;
}

.featured-laws tbody tr:hover {
    background-color: var(--extra-light);
}

.featured-laws tbody tr:hover .law-title {
    color: var(--link-blue);
}

.featured-empty {
    cursor: default;
}

.featured-empty:hover {
    background-color: unset!important;
}

.featured-empty p {
    text-align: center;
}
