.usr-mgt {
    margin-top: 16px;
}

/*.user-management-row p {*/
/*    margin: 0;*/
/*}*/

/*.user-management .user-top {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    flex-wrap: nowrap;*/
/*}*/

/*.user-management-row .name {*/
/*    font-weight: 400;*/
/*}*/

/*.user-management-row .email {*/
/*    font-weight: 300;*/
/*    text-align: right;*/
/*    float: right;*/
/*}*/

/*.user-management-row .role {*/
/*    font-size: 14px;*/
/*    float: right;*/
/*}*/


/*.user-management-row {*/
/*    max-width: 600px;*/
/*    padding: 16px;*/
/*    margin: 16px;*/
/*    cursor: pointer;*/
/*}*/

/*.user-management-row:hover {*/
/*    background-color: var(--extra-light);*/
/*    border-radius: 12px;*/
/*    color: var(--link-blue);*/
/*}*/

.user-management.add-user {
    display: flex;
}

.user-management.add-user > img {
    width: 20px;
    padding-right: 4px;
}

.user-management.add-user > img,
.user-management.add-user > span {
    vertical-align: center;
}

/** User grid */
.user-grid {
    margin-top: 16px;
}

/** END User grid */
/** User row */

.user-row {
    display: grid;
    grid-column-gap: 4px;
    grid-row-gap: 0;
    padding: 12px;
    transform: translateX(-12px);
}

.user-row:hover {
    background-color: var(--extra-light);
    border-radius: 12px;
}

.user-row:hover div {
    color: var(--link-blue);;
}

.user-row .user-name {
    color: var(--grey);
    font-weight: 400;
    grid-row: 1;
    grid-column: 1;
}

.user-row .user-email {
    color: var(--grey);
    font-weight: 300;
    grid-row: 1;
    grid-column: 2;
    justify-self: right;
    text-align: right;
    overflow-wrap: anywhere;
}

.user-row .user-role {
    color: var(--grey);
    font-size: 14px;
    grid-row: 2;
    grid-column: 2;
    justify-self: right;
    text-align: right;
}

/** END User row */
