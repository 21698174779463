.scoring h2 {
    margin-bottom: 64px;
}

.scoring h4 {
    font-size: 16px;
}

.scoring p {
    margin-top: 0;
    margin-bottom: 32px;
    font-weight: 300;
    font-size: 16px;
    white-space: pre-wrap;
}

.scoring .sub-content {
    margin-bottom: 0;
}

/** legend container */
.scoring .legend-container {
    margin: 24px 0;
}



/** Scale heading */
.scale-heading {
    display: inline;
}
.scale-heading > .circle {
    margin-right: 4px;
}

.scale-heading > h4 {
    display: inline;
    margin: 0;
}

/** Example section */
.example-section p {
    padding-left: 6px;
}

.example-section .example {
    margin: 0 0 8px 0;
    font-style: italic;
}

.example-section .example-content {
    font-style: italic;
}


/** Standards list section */
.scoring .standards-list > ul {
    list-style: none;
    padding: 0;
}

.scoring .standards-list > ul li:not(:first-child) {
    margin-top: 10px;
}

.scoring .standards-list .standard-content {
    vertical-align: middle;
}

.scoring .standards-list > ul .standard-content > * {
    vertical-align: middle;
}

.scoring .standards-list > ul .standard-content > span {
    font-weight: 300;
    font-size: 16px;
    margin-left: 16px;
    color: var(--grey);
}


/** Hack: aligns unpublished elements in standards section */
.scoring .standards-list .unpublished {
    margin-right: -4px;
    text-align: left;
    padding-left: 2px;
}

/** END Standards list section */
