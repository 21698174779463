.count-card {
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;

    background-color: var(--card-background);
    border-radius: 4px;
    padding: 6px 0 15px 0;
}

.count-card-count {
    font-weight: bold;
    font-size: 53px;
    line-height: 150%;
    letter-spacing: -0.05em;
}

.count-card-label {
    font-size: 21px;
    line-height: 150%;
    letter-spacing: -0.05em;
    margin-top: -15px;
}
