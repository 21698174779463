.search-filter-list, .search-country-list {
    margin-top: 25px;
}

.search-filter-title {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
    margin-bottom: 10px;
}

.search-filter-items > * {
    margin-top: 5px;
}
