.score {
    max-width: 380px;
}

.score h3 {
    margin-top: 0;
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 24px;
}

.score-score-pill {
    width: 100%;
    min-height: 50px;
    position: relative;
}

.score .contents img {
    padding-top: 8px;
    width: 18px;
    float: left;
}

.score .contents p {
    padding-left: 36px;
}

.score p {
    font-weight: 300;
    /*font-size: 12px;*/
}

.score a {
    font-weight: 600;
}

.score a:hover {
    text-decoration: underline;
}

.score ul {
    list-style-type: none;
    padding-left: 8px;
}

.score li {
    /*font-size: 12px;*/
    font-weight: 300;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--grey);
}

.score svg {
    padding-right: 16px;
}

.score .hidden-path {
    fill: transparent;
}


.meeting, .score .meeting {
    color: var(--scoring-meeting);
    fill: var(--scoring-meeting);
    font-weight: 600;
}

.mostly, .score .mostly {
    color: var(--scoring-mostly);
    fill: var(--scoring-mostly);
    font-weight: 600;
}

.partially, .score .partially {
    color: var(--scoring-partially);
    fill: var(--scoring-partially);
    font-weight: 600;
}

.not, .score .not {
    color: var(--scoring-not);
    fill: var(--scoring-not);
    font-weight: 600;
}

.disregard, .score .disregard {
    color: var(--scoring-disregard);
    fill:  var(--scoring-disregard);
    font-weight: 600;
}

.na, .score .na {
    color: var(--grey);
    fill: var(--grey);
    font-weight: 600;
}

.score-no-pointer .meeting {
    color: var(--scoring-meeting);
    fill: var(--scoring-meeting);

}

.score-no-pointer .mostly {
    color: var(--scoring-mostly);
    fill: var(--scoring-mostly);

}

.score-no-pointer .partially {
    color: var(--scoring-partially);
    fill: var(--scoring-partially);

}

.score-no-pointer .not {
    color: var(--scoring-not);
    fill: var(--scoring-not);

}

.score-no-pointer .disregard {
    color: var(--scoring-disregard);
    fill:  var(--scoring-disregard);

}

.score-no-pointer .na {
    color: var(--grey);
    fill: var(--grey);

}
.score-no-pointer span {
    font-weight: 300;
    text-align: right;
}
