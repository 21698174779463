.top-bar {
    background: var(--white);
    flex-direction: row;
    display: flex;
    padding: 0px 70px;
    position: sticky;
    top:0;
    z-index: 11;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.logo {
    flex-direction: row;
    float: left;
    display: inline-flex;

}

.logo img {
    width: 64px;
    height: 60px;
    vertical-align: middle;
    padding-right: 16px;
}

.logo h1{
    font-weight: 300;
    font-size: 22px;
    padding-top: 4px;
    white-space: nowrap;
    color: var(--black);
}

.logo a {
    text-decoration: none;
}

.top-bar .menu {
    flex-direction: row;
    margin-left: auto;
    display: inline-flex;
    align-self: center;

}
.menu ul {
    list-style-type: none;
    padding: 0;
    display:inline-flex;
    align-items: center;
}

.menu .nav-button{
    margin: 0 16px 0 16px;

    /*display:inline-flex;*/
}

.menu .nav-button .black a {
    font-size: 14px;
    color: var(--grey);
    text-decoration: none;
    cursor: pointer;
}

.menu .nav-button .login-item {
    font-size: 14px;
    color: var(--grey);
    text-decoration: none;
    cursor: pointer;
}

.menu .nav-button:hover .login-item {
    color: var(--link-blue);
}

.menu .nav-button:hover .black a {
    color: var(--link-blue);
}

.menu .nav-button .blue a{
    font-size: 14px;
    color: var(--selected-salmon);
    text-decoration: none;
}

.blur {
    display: none;
    visibility: hidden;
    opacity: 0;
}

.hamburger {
    display: none;

}

.text-small {
    display: none;
}

@media only screen and (max-width: 465px) {
    .text-wide {
        display: none;
    }
    .text-small {
        display: block;
    }
    .top-bar {
        padding: 4px 16px 0 16px;
    }
}

@media only screen and (max-width: 1200px) {
    .hamburger {
        position: fixed;
        display: block;
        right: 24px;
        top: 36px;
        z-index: 11;
    }

    .blur.active {
        display: block;
        visibility: visible;
        position: fixed;
        z-index: 9;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 80%;
    }

    .menu {
        -webkit-transition: width .3s ease-in-out;
        -moz-transition: width .3s ease-in-out;
        -o-transition: width .3s ease-in-out;
        transition: width .3s ease-in-out;

        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        width: 0;
        z-index: 10;
        background-color: white;
        box-shadow: -2px 0 2px -2px rgba(0,0,0,.2);
    }

    .menu.visible {
        width: 320px;
    }

    .menu ul {
        margin: 64px 0 0 0;
        display: block;
        z-index: 20;
    }

    .menu ul li {
        margin: 32px 0;
        padding-left: 48px;
    }
}
