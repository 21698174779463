.profile-details {
    padding: 32px;
    width: 100%;
    -moz-transition:all .3s ease-in;
    -webkit-transition:all .3s ease-in;
    -o-transition:all .3s ease-in;
    transition:all .3s ease-in;
    border-left-color: var(--lighter-grey);
    border-left-width: 1px;
    border-left-style: solid;
}
