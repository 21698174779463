.blog {
    background-color: var(--extra-light);
    padding: 16px;
    margin: 8px 0px 64px 0px;
    border-radius: 12px;
}

.blog .title {
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: 600;
    /*font-size: 12px;*/
}

.blog .subtitle {
    margin-top: 0;
    font-weight: 300;
    /*font-size: 12px;*/
}

.blog .date {
    padding-left: 8px;
}

.blog .spacing {
    margin: 8px 0;
}

.add-insight {
    display: inline-block;
    margin-left: 8px;
}

.add-insight > img {
    vertical-align: middle;
    width: 20px;
    padding-right: 4px;
}

.add-insight > span {
    vertical-align: middle;
}

.submit-spacer {
    margin-top: 32px;
}

/* Comments */
.view-comments {
    margin-top: 48px;
}

.view-comments .single-comment {
    margin-top: 16px;
    padding-top: 16px;
    cursor: pointer;
    border-top: 1px solid var(--lighter-grey);
}

.view-comments .single-comment:first-of-type {
    border-top: 0px;
}

.view-comments .single-comment > h4 {
    margin-top: 0;
    margin-bottom: 2px;
}

.view-comments .single-comment > p {
     margin-top: 2px;
     margin-bottom: 0;
     font-style: italic;
 }
/* END Comments */
