.edit-law .edit-law-container {
    margin-bottom: 48px;
    padding-bottom: 48px;
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid var(--border-line-grey);
    border-bottom: 1px solid var(--border-line-grey);
}

.edit-container {
    max-width: 800px;
}

.edit-law .back-img {
    vertical-align: middle;
    padding-right: 4px;
}

.edit-law .manage-comments-container {
    padding-bottom: 64px;
    margin-bottom: 48px;
    border-bottom: 1px solid var(--border-line-grey);
}

.edit-law .blog-container {
    max-width: 800px;
    margin-bottom: 24px;
}

.edit-law .blog-container > h3 {
    margin-top: 0;
}

.edit-law .blog-container > div > .blog {
    margin: 16px 0 32px 0;
}

.edit-law .manage-law-container {
    margin-bottom: 64px;
    border-top: 1px solid var(--border-line-grey);
    padding-top: 24px;
}
.edit-law .manage-law-container > h3 {
    margin-bottom: 0;
}
.edit-law .manage-law-container > h4 {
    margin: 0;
}

.edit-law .manage-law-container > .flag-btn {
    margin: 16px 0;
}
