.benchmarks{
    display: flex;
    margin: auto;
    flex-direction: column;

}
html{
    scroll-behavior: smooth;

}
.benchmarks h2{
    margin-bottom: 48px;
}
.benchmarks-layout{
    display: flex;
}
.benchmarks-content{
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}
.links-to-questions {
    min-width: 35%;
    margin: 0 0 20px 20px;
}

@media only screen and (max-width: 1086px) {
    .benchmarks-layout{
        flex-wrap: wrap;
    }
    .border-benchmark {
        display: none;
    }
}

.border-benchmark {
    background: var(--light-grey);
    margin-top: 50px;
    min-width: 1px;
    height: 500px;
}

.criteria{
    margin-top: 20px;
}

.criteria .id{
    scroll-margin-top: 120px;
}

.standards-result tr:nth-child(even) td{
    background: var(--extra-light);
}

.question td:hover{
    /*box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.6);*/
    /*font-weight: 600;*/
    cursor: pointer;
    color: black;
}

.question td {
    padding: 0;
}

.question td p{
    margin-block-start: 0;
    margin-block-end: 5px;
    font-weight: 600;
}

.criteria h3 {
     margin-bottom: 0;
}

.criteria h4 {
     margin-top: 8px;
    margin-bottom: 0;
    /*font-style: italic;*/
    font-weight: 300;
}

.criteria-content{
    display: flex;
    flex-direction: row;
}
.criteria-content .filter{
    margin-top: 18px;
    min-width: 22%;
}

.criteria .question{

    /*background: var(--extra-light);*/
    border-radius: 12px;
    width: fit-content;
}


/** Nav Links */
.nav-question p {
    margin: 4px 0;
}

.nav-question {
    cursor: pointer;
}

.nav-question:hover {
    background-color: var(--extra-light);
}

.nav-question:hover p {
    color: var(--link-blue);
}
/** END Nav Links */


/** Hiding Categories */
@media only screen and (max-width: 600px) {
    .benchmarks-route .category-item .title, .category-item .title-selected {
        display: none;
    }

    .benchmarks-route .criteria-content .filter {
        min-width: unset;
    }

    .benchmarks-route .category-item img {
        padding-right: 0;
    }
}
/** END Hiding Categories */
