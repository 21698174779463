.profile-edit tr:nth-child(even) td {
    background: transparent;
}

.profile-edit td {
    padding: 8px 0;
    vertical-align: middle;
    min-width: 300px;
    width: 400px;
}

.profile-edit .text-input {
    margin: 0;
}

.profile-edit img {
    padding-right: 4px;
}

.profile-edit .profile-label {
    margin-top: 4px;
    margin-bottom: 4px;
}

.profile-edit .edit-div {
    /*width: 96%;*/
}


.profile-edit .profile-email {
    font-weight: 400;
    font-size: 16px;
    color: var(--grey);
}
