.viewer span {
    color: var(--grey);
}

.viewer p {
    font-weight: 300;
    /*font-size: 12px;*/
}

/*.public-DraftStyleDefault-ltr {*/
/*    font-size: 12px;*/
/*    font-weight: 300;*/
/*}*/
