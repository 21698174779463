.search-index-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 115px;
    margin: 2px;

}
.search-index-box{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
}

@media only screen and (max-width: 460px) {
    .search-index-box {
        justify-content: center;
    }
}

.search-index-item p{
    text-align: center;
    font-weight: 400;
    width: 105px;
}

.search-index-box :hover p {
    color: var(--link-blue-hover);
}

.search-index-item .icon-container{
    height: 90px;
    width: 90px;
    border-radius: 45px;
    background: var(--extra-light);
    display: flex;
    justify-content: center;
}
.search-index-item .icon-container img{
    height: 50px;
    width: 50px;
    margin: auto;

}
.search-index-item:hover .icon-container img{
    filter: invert(15%) sepia(64%) saturate(623%) hue-rotate(184deg) brightness(96%) contrast(90%);
}

.search-index-item:hover, .search-index-item :hover {
    cursor: pointer;
}

.search-index-box{
    display: flex;
}
