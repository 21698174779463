.evaluation-container {
    /* Absolute to cover footer & fill screen width */
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    overflow-y: hidden;
}

.evaluation-container .pdf-wrapper {
    flex-grow: 1000;
    height: 100%;
    min-width: 40%;
}
.evaluation-container .panel-wrapper {
    height: 100%;
    /*min-width: 20%;*/
    /*max-width: 30%;*/
    width: 25%;
    margin: 0;
}

/** beyond a certain size hide the pdf */
@media only screen and (max-width: 600px) {
    .evaluation .pdf-wrapper {
        display: none;
    }

    .evaluation .panel-wrapper {
        width: 100%;
    }
}
