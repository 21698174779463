.question-cell {
    width: 40%;
}
.question-cell-short {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    display: none;
}

.score-detail tr:nth-child(even) .question-cell,
.score-detail tr:nth-child(even) .question-cell-short {
    background-color: var(--extra-light);
}

/** legend */
.score-detail .grid-legend {
    vertical-align: top;
    text-align: center;
    background: var(--extra-light);
    font-weight: 600;
    font-size: 14px;
    color: var(--grey);
}

.score-detail .grid-legend:nth-child(2) { border-top-left-radius: 12px; }
.score-detail .grid-legend:last-child { border-top-right-radius: 12px; }
.score-detail .grid-legend:nth-child(2) { border-bottom-left-radius: 12px; }
.score-detail .grid-legend:last-child { border-bottom-right-radius: 12px; }

.grid-cell {
    min-height: 64px;
    min-width: 64px;
    width: 12%; /* 60% / 5 */
    position: relative;

    text-align: center;
    vertical-align: middle;
    color: var(--black);

    border: 1px solid #BBBBBB;

    --opacity: 1;
    background-color: rgba(var(--color), var(--opacity));
    background-clip: padding-box;
}

.grid-cell-na { --color: 152,153,167; }
.grid-cell-regressive { --color: 255,126,120; }
.grid-cell-blind { --color: 247,195,70; }
.grid-cell-neutral { --color: 116,232,226; }
.grid-cell-responsive { --color: 158,226,90; }

.grid-cell-empty {
    opacity: 0.3;
}

@media only screen and (max-width: 574px) {
    .question-cell {
        display: none;
    }
    .question-cell-short {
        display: block;
    }
}

@media only screen and (max-width: 702px) {
    .score-detail svg.circle-padding.result {
        margin: 12px;
    }
}

.score-detail .grid-legend > span {
    width: 100%;
    text-align: center;
}
