.bar {
    width: 100%;
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--lighter-grey);
}

.bar .progress {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    height: 24px;
    background-color: var(--link-blue);
}

