.text-input {
    font-weight: 300;
    background: transparent;
    font-size: 14px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    color: var(--grey);
    border-color: var(--light-grey);
    transition: 0.2s;
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
}

textarea[class~="text-input"] {
    resize: none;
    height: 128px;
    margin-bottom: -6px; /* Removes textarea bottom margin offset */
}

.eye {
    position: absolute;
    float: right;
    transform: translate(-32px, 6px);
    z-index: 1;
    cursor: pointer;
}

/* for password */
.text-input.password {
    padding-right: 36px;
}

.text-input.warning {
    border-color: red;
}

.text-input:focus {
    border-color: var(--shading-grey);
    transition: 0.2s;
}

.text-input::placeholder {
    color: var(--shading-grey);
}

