.header {
    width: 100%;
}

.header-breadcrumbs {
    padding-left: 78px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.header-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 150px;
    padding: 34px 78px;
    box-sizing: border-box;

    background-color: var(--card-background);
}

.header-has-score {
    border-bottom: 10px solid;
}

.header-left {
    display: flex;
    flex-direction: column;
}

.header-evaluators {
    font-size: 18px;
    color: var(--text-black);
}

.header .header-meta {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 20px;
    font-weight: bold;
    color: var(--text-black);
}

.header .header-meta .year {
    margin-left: 20px;
}

.header .country {
    display: flex;
    align-items: center;
}
.header .country img {
    width: 33px;
    margin-right: 10px;
}

.header-score {
    margin-left: 10px;
}

.header h2 {
    padding-bottom: 10px;
    color: var(--text-black);
    font-weight: bold;
    font-size: 32px;
    margin: 0px;
}

.header-score-meeting { border-color: var(--scoring-meeting); }
.header-score-mostly { border-color: var(--scoring-mostly); }
.header-score-partially { border-color: var(--scoring-partially); }
.header-score-not { border-color: var(--scoring-not); }
.header-score-disregard { border-color: var(--scoring-disregard); }
.header-score-na { border-color: var(--scoring-na); }
