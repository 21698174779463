.legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: var(--extra-light);
    border-radius: 12px;
    padding: 4px;
}

@media only screen and (max-width: 1086px) {
    .legend {
        flex-wrap: wrap;
    }
}

.legend .elem svg {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-left: 12px;
}

.legend .elem span {
    margin: auto 12px auto 4px;
    color: var(--grey);
    font-weight: 600;
    font-size: 14px;
    align-self: center;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.legend .elem {
    display: flex;
    flex-wrap: nowrap;
}
