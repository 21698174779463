footer {
    margin: 96px 8px 4px 8px;
    z-index: -1;
}
footer .content {
    padding-left: 58px;
    height: 4px;
    background-color: var(--selected-salmon);
    border-radius: 12px;
}

footer p {
    margin: 0;
    font-size: 10px;
}
