.video-container{
    padding: 10px 25px;
    display: flex;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px; /* future proofing */
    align-items: center;
}

.video-container iframe {
    width: 600px;
    height: 352px;
}

@media only screen and (max-width: 1086px) {
    .video-container {
        display: block;
    }
    .video-container iframe {
        width: 100%;
        height: 352px;
    }
}

@media only screen and (max-width: 460px) {
    .video-container iframe {
        height: auto;
    }

    .video-container ul{
        margin-top: 0;
        padding-left: 0;
    }
}
