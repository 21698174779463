
.profile .content {
    display: flex;
}

/** Mobile*/
.profile .content .sidebar {
    position: relative;
    width: 42px;
    flex-shrink: 0;
    -moz-transition:all .3s ease-in;
    -webkit-transition:all .3s ease-in;
    -o-transition:all .3s ease-in;
    transition:all .3s ease-in;
    overflow: hidden;
}

/*Desktop*/
@media only screen and (min-width: 1024px) {
    .profile .content .sidebar {
        width: 250px;
        flex-shrink: 0;
    }
}
