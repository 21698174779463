.detail {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 50px;
    margin: 8px;
    margin-top: 50px;
    padding: 0 70px;
}

.detail .detail-component {
    /*overflow: hidden;*/
}

.detail .sidebar {
    min-width: 300px;
    max-width: 450px;
}

.detail-sidebar-scoring {
    margin-top: 20px;
    padding: 30px 36px;
    background-color: var(--card-background);
    border-radius: 4px;
}

@media only screen and (max-width: 1086px) {
    .detail {
        display: flex;
        flex-wrap: wrap;
    }
}
