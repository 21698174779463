.about p {
    white-space: pre-wrap;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 24px;
    font-style: italic;
}

.about h4 {
    font-size: 16px;
    margin-bottom: 0;
}

.about .heading-link {
    padding-left: 8px;
}

.about a:hover {
    text-decoration: underline;
}

.about .segment {
    margin-top: 32px;
    margin-bottom: 36px;
}

.about-content {
    padding-left: 12px;
}

.about-logo-uni {
    width: 128px;
    float: right;
}
