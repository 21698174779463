.glossary {
    /*max-width: 1000px;*/
}

.glossary h2 {
    margin-bottom: 64px;
}

.glossary h4 {
    font-size: 16px;
    margin-bottom: 4px;
}

.glossary p {
    margin-top: 0;
    margin-bottom: 32px;
    font-weight: 300;
    font-size: 16px;
    font-style: italic;
}
