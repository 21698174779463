.score-pill {
    box-sizing: border-box;
    padding: 5px 16px;
    border-radius: 8px;

    color: var(--white);
    font-weight: 600;
    font-size: 15px;

    display: inline-flex;
    align-items: center;
    text-align: center;
}

.score-pill.score-pill-bold {
    font-size: 18px;
    font-weight: bold;
}

.score-pill.score-pill-fill {
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 15px;
}

.score-pill-meeting { background: var(--scoring-meeting); }
.score-pill-mostly { background: var(--scoring-mostly); }
.score-pill-partially { background: var(--scoring-partially); }
.score-pill-not { background: var(--scoring-not); }
.score-pill-disregard { background: var(--scoring-disregard); }
.score-pill-na { background: var(--scoring-na); }
