.profile-add-law {
    max-width: 800px;
}

.profile-add-law table {
    width: 100%;
}

.profile-add-law td:nth-child(2) {
    width: 360px;
}

.profile-add-law td {
    background-color: var(--white)!important;
}

.file-input {
    width: 100%;
    border-width: 1px;
    border-style: dashed;
    border-color: var(--light-grey);
    border-radius: 12px;
}

.file-input.active {
    border-style: solid;
    background-color: var(--extra-light);
}

.file-input.dragging {
    border-style: solid;
}

.file-input .selected {
    background-color: var(--grey);
}

.file-input.warning {
    border-color: red!important;
}


.file-input-message {
    text-align: center;
    margin: 64px 0;
}

.file-input-message.file-selected {
    font-weight: 600;
}
