.edit-category {
    width: 100%;
}

.edit-category .question {
    border-radius: 12px;
    transform: translateX(-16px);
    background-color: var(--extra-light);
    padding: 16px;
    display: inline-block;
}

.edit-category p {
    margin: 0;
}

.edit-category .no-category {
    margin: 32px 16px;
    padding-bottom: 16px;
    font-style: italic;
}

/** Edit title */
.edit-title {
}

.edit-title.inactive {
    display: inline-block;

    cursor: pointer;
}

.edit-title.inactive img {
    width: 18px;
    text-align: center;
    vertical-align: middle;
}

.edit-title.inactive span {
    padding-left: 2px;
    font-weight: 400;
    font-size: 16px;
    color: var(--link-blue);
}

.edit-title input {
    width: 256px;
}

.edit-title button {
    font-weight: 400;
    font-size: 16px;
    margin: 0 8px;
}
.edit-title .cancel {
    color: var(--error-text);
}

/** END Edit title */

/** Navigation */
.navigator {
    margin: 32px 0 16px 0;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--border-line-grey);
}

.navigator h4 {
    margin: 0;
}

.navigator a {
    display: block;
    padding: 16px;
    transform: translateX(-16px);
    font-weight: 300;
    color: var(--grey);
}

.navigator a:nth-child(odd) {
    background-color: var(--extra-light);
    border-radius: 12px;
}

.navigator a:hover {
    color: var(--link-blue);
}
/** END Navigation */


/** Criteria group */
.edit-category .criteria-group {
    scroll-margin-top: 128px;
    margin: 48px 0;
    padding-bottom: 48px;
    border-bottom: 1px solid var(--border-line-grey);
}
/** END Criteria group */

/** Benchmarks */
.edit-category .benchmarks {
    /*margin-left: 16px;*/
}

.edit-category .benchmarks > div {
    padding: 16px;
    transform: translateX(-16px);
    cursor: pointer;
    border-radius: 12px;
}

.edit-category .benchmarks > div:hover {
    background-color: var(--extra-light);
}

.edit-category .benchmarks > div:hover > p {
    color: var(--link-blue);
}

.edit-category .benchmarks > div:hover > span {
    color: var(--link-blue-hover);
}

.edit-category .benchmarks > div:active {
    background-color: var(--border-line-grey);
    border-radius: 12px;
}


/** Add Benchmark */
.edit-category .add-benchmark {
    display: inline-block;
}

.edit-category .add-benchmark img {
    width: 24px;
    text-align: center;
    vertical-align: middle;
}

.edit-category .add-benchmark span {
    padding-left: 4px;
    color: var(--link-blue);
}

/** END Add Benchmark */
/** END Benchmarks */


/** Benchmark modal */
.benchmark-modal h3 {
    margin-bottom: 32px;
}

.benchmark-modal .spacing .box-area {
    margin: 32px 0;
}

.benchmark-modal .spacing {
    margin: 16px 0;
}

.benchmark-modal .benchmark-content {
    max-width: 80%;
    margin: 64px auto;
}
