/** Item */
.law-item td {
    padding: 22px;
    border-radius: 4px;
    font-weight: 300;
    cursor: pointer;
    background-color: var(--card-background);

    display: grid;
    grid-template-areas:
        "title"
        "stats"
        "score";
    grid-auto-rows: auto;
    gap: 15px;
}

.law-item.stretch td {
    grid-template-areas:
        "title stats"
        "score stats";
    grid-template-columns: auto min-content;
    gap: 10px;
}

.law-item .l-score,
.law-item .score-pill,
.law-item .l-stats .meta,
.law-item .l-stats .meta img {
    cursor: pointer;
}

.law-item:hover .title {
    color: var(--link-blue);
}

.law-item:hover .l-stats .meta {
    cursor: pointer;
    color: black;
}
/** END Item */


/** Item Title */
.law-item .title {
    grid-area: title;
    margin: 0;

    font-size: 18px;
    line-height: 25px;
    color: var(--black);
}
/** END Item Title */


/** Item Score */
.law-item .l-score {
    grid-area: score;
}
/** END Item Score */


/** Item Stats */
.law-item .l-stats {
    display: flex;
    flex-wrap: nowrap;
    grid-area: stats;
}

.law-item.stretch .l-stats {
    justify-content: space-around;
}

.law-item .l-stats img {
    padding-right: 4px;
    width: 16px;
}

.law-item .l-stats .meta {
    display: flex;
    align-items: center;
    justify-content: left;
    color: #424242;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.02em;
    white-space: nowrap;

    margin: 10px;
}

/*.law-item .l-stats .meta.country {*/
/*    min-width: 120px;*/
/*}*/

.law-item .l-stats .meta.country img {
    width: 24px;
    padding-right: 8px;
}

/*.law-item .l-stats .meta.year {*/
/*    min-width: 80px;*/
/*}*/

/*.law-item .l-stats .meta.number {*/
/*    min-width: 40px;*/
/*}*/

/*.law-item .l-stats .meta.assessed {*/
/*    min-width: 40px;*/
/*}*/
/** END Item Stats */


@media only screen and (max-width: 460px) {
    .law-item p.title {
        margin-bottom: 4px;
    }

    /* .law-item .detail {
        display: block;
    } */

    .law-item .l-stats {
        margin-left: 10px;
        flex-direction: column;
        justify-content: center;
    }

    .law-item .l-stats {
        margin-left: 10px;
        flex-direction: column;
        justify-content: center;
    }

    .law-item .l-stats > div {
        margin: 4px 0;
    }
}
