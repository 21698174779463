.search{
    display: flex;
    flex-direction: column;
}

.search-content{
    margin-top: 20px;
    display: grid;
    grid-template-areas:
        "title search"
        "filters results";
    grid-template-columns: 250px auto;
    gap: 40px;
    position: relative;
}

.search h2, .search h3 {
    margin: 0;
}

.search h3, .search h4 {
    color: var(--black);
}

.search h2 {
    color: var(--text-black);
    font-size: 32px;
    line-height: 44px;

    align-self: end;
}

.search h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
}

.filter-list {
    -moz-transition: width .5s ease-in-out, opacity 0.5s linear;
    -webkit-transition: width .5s ease-in-out, opacity 0.5s linear;
    -o-transition: width .5s ease-in-out, opacity 0.5s linear;
    transition: width .5s ease-in-out;

    grid-area: filters;
}

.filter-list * {
    white-space: nowrap;
}

.search-bar-row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    grid-area: search;
}

.search-result {
    padding-left: 30px;
}

.search .result-panel {
    grid-area: results;
}

.search-bar-row .search-wrapper {
    flex-grow: 1;
}

.search-sorting {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.search-sorting-dropdowns {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

.search-sorting-dropdowns > :not(:first-child) {
    margin-left: 10px;
}

.search .dropdown {
    width: 25%;
    min-width: 150px;
}

@media only screen and (max-width: 932px) {
    .search-content {
        grid-template-areas:
            "title"
            "search"
            "results";
        grid-template-columns: auto;
    }

    .filter-list {
        position: absolute;
        top: 170px;

        width: 300px;
        padding: 16px;

        z-index: 9;
        overflow-x: hidden;
        overflow-y: scroll;

        background-color: var(--white);
        border: 1px solid var(--lighter-grey);
        border-radius: 12px;

        transition: width .5s, border-color 0.5s linear, padding 0.5s linear;
    }

    .filter-list.filters-hidden::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    .filter-list.filters-hidden {
        width: 0;
        /*opacity: 0;*/
        padding-left: 0;
        padding-right: 0;
        border-color: transparent;
    }
}
