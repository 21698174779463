.leaders-page .leaders {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, 305px);
    justify-content: space-around;

    margin: 50px 0;
}

.leaders-page .leader-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    padding-bottom: 20px;

    background-color: var(--card-background);
    border-radius: 4px;
    text-align: center;
}

.leaders-page .leader-component h4 {
    flex-grow: 1;
    margin: 20px 0;
    font-weight: 600;
    font-size: 23px;
    color: var(--black);
}

.leaders-page .leader-image {
    width: 253px;
    height: 332px;
    border-radius: 10px;
    object-fit: cover;
}

.leaders-page .leader-component .read-more {
    font-weight: normal;
    font-size: 16px;
}

.leaders-page .shortened-content {
    padding-right: 256px;
}

.leader-modal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    --margin: 40px;
    --half-vert-gap: calc(20px / 2);
    --half-horiz-gap: calc(60px / 2);
    margin: calc(var(--margin) - var(--half-vert-gap)) calc(var(--margin) - var(--half-horiz-gap));
}

.leader-modal > * {
    margin: var(--half-vert-gap) var(--half-horiz-gap);
}

.leader-modal h4 {
    font-weight: bold;
    font-size: 32px;
    color: var(--black);
    margin: 0;
    margin-bottom: 20px;
}

.leader-modal .leader-image {
    width: 325px;
    height: 423px;
}

.leader-modal-text {
    flex: 1 1 300px;
    min-width: 300px;
    max-width: 800px;

    display: flex;
    flex-direction: column;
}

.leader-modal-more {
    width: max-content;
    margin-top: 40px;
}

@media only screen and (max-width: 1200px) {
    .leaders-page .shortened-content {
        padding-right: unset;
    }

    /* .leader-modal {
        flex-direction: column;
    } */

    /* .leader-modal-text {
        max-width: 500px;
        margin-left: 0;
        margin-top: 20px;
    } */
}

@media only screen and (max-width: 702px) {
    .leaders-page .leader-component {
        flex-direction: column;
    }

    .leaders-page .leader-component img {
        align-self: center;
        margin-left: 0;
    }

    .leaders-page .leaders {
        grid-template-columns: 300px;
    }
}
