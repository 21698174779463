.evaluation-panel {
    height: 100%;
}

.evaluation-panel  p {
    margin-block-end: 2px;
}

.pdf-container{
    margin-left: 60px;
}
.panel-wrapper{
    margin: 0 60px;
    background-color: white;
}
.multiplechoice-container{
    padding-top: 20px;
    padding-bottom: 25px;
}
.multiple-choice{
    display: flex;
    width: 200px;
    justify-content: space-between;
}
.multiple-choice label {
    display: flex;
    font-weight: 600;
    font-family: 'Nunito Sans', sans-serif;
    color: var(--grey);
    padding-bottom: 10px;
}
.multiple-choice label img {
    padding: 3px 0 0 4px;

}

.multiple-choice input[type="radio"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 52%;
    width: 16px;
    height: 16px;

    border: 1px solid #999;
    transition: 0.2s all linear;
    margin: 0;
}

.multiplechoice-container.warning input[type="radio"] {
    border-color: red;
}

.multiple-choice.regressive input[type="radio"]:checked {
    background-color: var(--choice-regressive);
}

.multiple-choice.blind input[type="radio"]:checked {
    background-color: var(--choice-blind);
}

.multiple-choice.neutral input[type="radio"]:checked {
    background-color: var(--choice-neutral);
}

.multiple-choice.responsive input[type="radio"]:checked {
    background-color: var(--choice-responsive);
}


.multiple-choice.inconclusive > label,
.multiple-choice.na > label {
    font-style: italic;
    font-weight: 300;
}
.multiple-choice.inconclusive input[type="radio"]:checked,
.multiple-choice.na input[type="radio"]:checked {
    background-color: var(--choice-na);
}
