.radio-score {
    margin: 4px 0;
    display:flex;
    align-items:center;
    cursor: pointer;
    color: var(--grey);
}

.radio-score .score-warning {
    color: red;
}

.radio-score:hover span:not(.score-warning) {
    color: black;
}

.radio-score img, .radio-score svg {
    padding-right: 16px;
    padding-bottom: 2px;
}

.radio-score span {
    vertical-align: center;
}
