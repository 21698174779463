.results table {
    width: 100%;
    border-spacing: 0px 17px;
    border-collapse: separate;
}

.results tr {
    width: 100%;
    cursor: pointer;
}

.results tr:hover .law-title {
    color: var(--link-blue);
}

.results tr:hover {
    background-color: var(--extra-light);
}

.results tr td{
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}


.results .details {
    display: flex;
    justify-content: flex-end;
}

.results .details img {
    padding-right: 4px;
}

.results .details span {
    padding: 8px;
}

.details-year {
    width: 128px;
}

.details-number {
    width: 128px;
}

.details-country {
    width: 168px;
}

.details-score {
    width: 100%;
    text-align: right;
}
