.analysis {
    max-width: 800px;
    order: 2;
}

.analysis h5 {
    margin: 0;
    margin-bottom: 4px;
}

.analysis .description {
    margin-top: 4px;
    font-weight: 300;
    /*font-size: 14px;*/
    font-style: italic;
}

.analysis .title {
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    align-items: center;
}
.analysis .title button {
    margin-left: 50px;
}

.analysis .analysis-back {
    margin-bottom: 20px;
}

.analysis .title h3 {
    color: var(--text-black);
    font-size: 24px;
    font-weight: bold;
}

.analysis .selector {
    margin: auto 0;
}

.analysis .analysis-blog {
    margin-top: 100px;
}

.analysis .analysis-alert {
    width: 100%;
    margin: 16px 0;
}

.analysis .analysis-alert.no-load {
    margin-top: 32px;
}

.sidebar-mobile {
    display: none;
}

@media only screen and (max-width: 1086px) {
    .sidebar-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }
}

@media only screen and (max-width: 702px) {
    .analysis .title {
        margin-bottom: 16px;
    }
}


/** Comments */
.comments-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-black);
}

.comments-description {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 48px;
    color: var(--black);
}
/** END Comments */
