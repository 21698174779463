:root {
    --white: #ffffff;
    --black: #000000;
    --grey: #4A4A4A;
    --shading-grey: #C0C0C0;
    --light-grey: #D8D8D8;
    --lighter-grey: #DFDFDF;
    --extra-light: #FAFAFA;

    --border-line-grey: #F0F0F0;

    --link-blue: #3B5998;
    --link-blue-hover: #3B5998;
    --link-blue-disabled: #6c84a9;

    --selected-salmon: #FF7E79;

    --scoring-meeting: #41AC3E;
    --scoring-mostly: #ACC853;
    --scoring-partially: #EBB141;
    --scoring-not: #DE6337;
    --scoring-disregard: #D83933;
    --scoring-na: #A5A6B6;

    /** Choice colors - used in the law detail score breakdown */
    --choice-responsive: #9EE25A;
    --choice-neutral: #74E8E2;
    --choice-blind: #F7C346;
    --choice-regressive: #FF7E78;
    --choice-na: #858F8E;

    --scoring-meeting-hover: #56BB5B;
    --scoring-mostly-hover: #3fAAAb;
    --scoring-partially-hover: #B87D1A;
    --scoring-not-hover: #874C53;
    --scoring-disregard-hover: #9C0214;
    --scoring-na-hover: black;
    --max-width: 1500px;

    --error-text: #a94442;
    --error-body: #f2dede;
    --error-border: #ebccd1;

    --success-text: #3c763d;
    --success-body: #dff0d8;
    --success-border: #d6e9c6;

    --info-text: #31708f;
    --info-body: #d9edf7;
    --info-border: #bce8f1;

    --selected-category: #FF7E78;
    --text-black: #333333;
    --card-background: #F2F2F2;
}

/* latin-ext */
@font-face {
    font-family: 'NunitoSans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('NunitoSans ExtraLight'), local('NunitoSans-ExtraLight'), url('fonts/NunitoSans-ExtraLight.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: 'NunitoSans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('NunitoSans ExtraLight'), local('NunitoSans-ExtraLightItalic'), url('fonts/NunitoSans-ExtraLightItalic.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'NunitoSans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('NunitoSans Light'), local('NunitoSans-Light'), url('fonts/NunitoSans-Light.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: 'NunitoSans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('NunitoSans Light'), local('NunitoSans-LightItalic'), url('fonts/NunitoSans-LightItalic.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'NunitoSans';
    font-style: normal;
    font-weight: 400;
    src: local('NunitoSans Regular'), local('NunitoSans-Regular'), url('fonts/NunitoSans-Regular.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'NunitoSans';
    font-style: italic;
    font-weight: 400;
    src: local('NunitoSans Regular'), local('NunitoSans-Italic'), url('fonts/NunitoSans-Italic.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'NunitoSans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('NunitoSans SemiBold'), local('NunitoSans-SemiBold'), url('fonts/NunitoSans-SemiBold.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: 'NunitoSans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('NunitoSans SemiBold'), local('NunitoSans-SemiBoldItalic'), url('fonts/NunitoSans-SemiBoldItalic.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'NunitoSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('NunitoSans Bold'), local('NunitoSans-Bold'), url('fonts/NunitoSans-Bold.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: 'NunitoSans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('NunitoSans Bold'), local('NunitoSans-BoldItalic'), url('fonts/NunitoSans-BoldItalic.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'NunitoSans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('NunitoSans ExtraBold'), local('NunitoSans-ExtraBold'), url('fonts/NunitoSans-ExtraBold.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: 'NunitoSans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('NunitoSans ExtraBoldItalic'), local('NunitoSans-ExtraBoldItalic'), url('fonts/NunitoSans-ExtraBoldItalic.ttf') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    margin: 0;
    font-family: 'NunitoSans', 'Nunito Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div {
    cursor: auto;
}

button {
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    background: none;
    border: none;
    padding: 0;
    color: var(--link-blue);
}

.main-content {
    flex-grow: 1;
}

.content-sizing {
    margin: 8px;
    padding: 0 70px;
}

@media only screen and (max-width: 702px) {
    .content-sizing {
        padding: 0 16px;
    }
}

.main-container{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    max-width: var(--max-width);
    min-height: 100vh;
}

h1 {
    font-weight: 800;
    color: var(--grey);
}

h2 {
    font-weight: 700;
    color: var(--grey);
}

h3{
    font-weight: 600;
    color: var(--grey);
}

h4 {
    font-weight: 400;
    color: var(--grey);
}

p{
    font-weight: 300;
    color: var(--grey);
}

a {
    text-decoration: none;
    color: var(--link-blue);
    cursor: pointer;
}

table {
    border-collapse: collapse;
}

td {
    padding: 8px;
}

/*
tr td:first-child { border-top-left-radius: 12px; }
tr td:last-child { border-top-right-radius: 12px; }
tr td:first-child { border-bottom-left-radius: 12px; }
tr td:last-child { border-bottom-right-radius: 12px; }

tr td:only-child { border-top-left-radius: 12px; }
tr td:only-child { border-top-right-radius: 12px; }
tr td:only-child { border-bottom-left-radius: 12px; }
tr td:only-child { border-bottom-right-radius: 12px; }
*/

.page-error-message {
    margin: 50px;
    padding: 40px;
    border-radius: 20px;
    background: #F2F2F2;
}

.page-error-message p {
    color: black;
    margin: 0;
}

.page-error-message-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.page-error-message h1 {
    font-weight: 300;
    font-size: 22px;
    color: black;
}

.page-error-message-logo {
    background-image: url("./assets/branding/logo.svg");
    width: 64px;
    height: 60px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 30px;
}
