.progress-indicator > div {
    background: #c0c0c0;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    min-width: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.25s;
}

.progress-indicator > div.warning {
    background: #f84141;
}

.progress-indicator > div.complete {
    background: var(--scoring-meeting);
}

.progress-indicator > div.active {
    background: #3b5998;
    transform: scale(1.4, 1.4);
}

.progress-indicator > div > img{
    width: 25px;
}

.progress-indicator > div > img.inner-icon {
    width: 15px;
}

.progress-indicator > div.active > img.inner-icon, .progress-indicator > div.warning > img.inner-icon {
    /* Turn any color icon into white */
    filter: brightness(0) invert(1);
}

.progress-indicator > div {
    color: var(--grey);
}

.progress-indicator > div.active,
.progress-indicator > div.warning,
.progress-indicator > div.complete {
    color: white;
}

.progress-indicator {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}
