.circle {
    display: inline-block;
    position: relative;
}

@keyframes SegmentAnimate {
    0% {
        stroke-dasharray: 0 3145;
    }
}

.circle .animate {
    animation: SegmentAnimate 1s ease-in-out forwards;
}


/*.circle .icon {*/
/*    width: 11px;*/
/*    height: 11px;*/
/*}*/

.circle .result {
    width: 48px;
    height: 48px;
}
