.add-category {
    max-width: 400px;
}

.add-category p {
    white-space: pre-wrap;
}

.warning-space {
    margin: 16px 0;
}


.add-category .button-wrapper {
    margin: 8px 0;
}
