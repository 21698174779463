.score-gradient {
    width: 350px;
    position: relative;
}

.score-gradient-meeting {
    --score-colour: var(--scoring-meeting);
    --pointer-position: 92.5%;
}

.score-gradient-mostly {
    --score-colour: var(--scoring-mostly);
    --pointer-position: 71.25%;
}

.score-gradient-partially {
    --score-colour: var(--scoring-partially);
    --pointer-position: 50%;
}

.score-gradient-not {
    --score-colour: var(--scoring-not);
    --pointer-position: 28.75%;
}

.score-gradient-disregard {
    --score-colour: var(--scoring-disregard);
    --pointer-position: 7.5%;
}

.score-gradient-na {
    --score-colour: var(--scoring-na);
}

.score-gradient-pointer {
    position: relative;
    transform: translateX(-50%);
    left: var(--pointer-position, 50%);

    /*Triangle styling*/
    --size: 18px;
    width: 0;
    height: 0;
    border-left: calc(var(--size) / 2) solid transparent;
    border-right: calc(var(--size) / 2) solid transparent;
    border-top: var(--size) solid var(--score-colour, black);
}

.score-gradient-bar {
    --bar-height: 18px; /* keep as an even number to prevent dodgy positioning when halving */
    height: var(--bar-height);
    width: 100%;

    background: linear-gradient(90deg, #D83933 8.22%, #DE6337 29.51%, #EBB141 48.76%, #ACC853 68.97%, #41AC3E 89.05%);
    border-radius: var(--bar-height);

    position: relative;
}

.score-gradient-dot {
    position: absolute;
    width: calc(var(--bar-height) - 4px);
    height: calc(var(--bar-height) - 4px);

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 50%;

    transform: translate(-50%, -50%);
    top: 50%;
    left: var(--pointer-position, 50%);
}

@media only screen and (max-width: 1086px) {
    .score-gradient {
        width: unset;
    }
}
