.profile-sidebar {
    padding-top: 64px;
    position: relative;
    left:0;
    width:250px;
    height:100%;
    margin:0 0 0 0;
    -moz-transition:all 200ms ease-in;
    -webkit-transition:all 200ms ease-in;
    -o-transition:all 200ms ease-in;
    transition:all 200ms ease-in;
}

.profile-sidebar:first-child {
    overflow:hidden;
     background: var(--white);
}

.profile-sidebar .title, .profile-sidebar .title-selected {
    opacity: 0;
    -moz-transition:all 200ms ease-in;
    -webkit-transition:all 200ms ease-in;
    -o-transition:all 200ms ease-in;
    transition:all 200ms ease-in;
}

.sidebar-divider {
    width: 90%;
    height: 1px;
    background-color: var(--lighter-grey);
}

/*Desktop*/
@media only screen and (min-width: 1024px) {
    .profile-sidebar .title, .profile-sidebar .title-selected {
        opacity: 1;
    }
}
