.pages-buttons {
    display: grid;
    margin: 32px 0;
}

.pages-buttons .leftside {
    justify-self: flex-start;
    grid-row: 1;
}

.pages-buttons .rightside {
    justify-self: flex-end;
    grid-row: 1;
}
