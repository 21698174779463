.breadcrumb {
    display: flex;
    align-items: center;
}

.breadcrumb img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    z-index: -1;
}

.breadcrumb span {
    font-weight: bold;
    font-size: 12px;
    color: var(--grey);
}
