
.cover-image {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-image-image {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
}

.cover-image-text {
    position: absolute;
    max-width: 600px;
    text-align: center;
    color: var(--white);

    font-weight: bold;
    font-size: 38px;
    line-height: 130.7%;
    letter-spacing: -0.02em;

    margin: 40px 0;
}
