.submit {
    width: 100%;
    color: var(--white);
    padding: 4px;
    border-radius: 8px;
    background-color: var(--link-blue);
}

.submit.red {
    background-color: var(--error-text);
}

.submit.red:hover {
    background-color: #7E3331;
}

.submit.red:disabled {
    background-color: rgba(180, 69, 68, 0.74);
}


.submit:hover {
    background-color: var(--link-blue-hover);
}

.submit:disabled {
    background-color: var(--link-blue-disabled);
    cursor: default;
}
