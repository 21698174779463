.placeholder {
    width: 100%;
    height: 100%;
}

.placeholder .placeholder-text {
    display: table;
    margin: auto;
    margin-top: 20px;
    color: var(--grey);
}

.wheel {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid var(--lighter-grey);
    border-radius: 50%;
    border-top-color: var(--link-blue);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes wheel-spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes wheel-spin {
    to { -webkit-transform: rotate(360deg); }
}
