/** Citations */
.citations {
    border-radius: 12px;
    background-color: var(--extra-light);
    padding: 8px;
}

.citations a, .home .citations p {
    margin: 0;
}

.citations a:hover {
    text-decoration: underline;
}

.citations ul {
    padding-left: 24px;
    list-style: none;
}

.citations li {
    content: counter(li);
    font-weight: 300;
    color: var(--grey);
    margin: 16px 0;
}

/** END Citations */
