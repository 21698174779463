.dropdown {
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;

    font-weight: 300;
    font-size: 14px;
    color: var(--grey);

    position: relative;
}

/** Dropdown toggle */
.dropdown .select {
    border: 1px solid var(--light-grey);
    border-radius: 12px;
    padding: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    align-self: center;
    box-sizing: border-box;
}

.dropdown:not(.flipped) .select.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
}

.dropdown.flipped .select.active {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: transparent;
}

.dropdown .select.warning, .dropdown .multi.warning {
    border-color: red;
}

.dropdown .select .indicator {
    position: relative;
    float: right;
    margin: 6px;
    z-index: 0;
}

.dropdown .select .indicator-multi {
    position: absolute;
    top: 20px;
    right: 16px;
    z-index: 0;
}

.dropdown.flipped .indicator, .dropdown.flipped .indicator-multi {
    transform: rotate(180deg);
}

.dropdown .select {
    cursor: pointer;
    width: 100%;
}

/* Internet explorer arrow */
.dropdown .select::-ms-expand {
    display: none;
}

.dropdown .select:active, .dropdown .select:hover, .dropdown .select::selection {
    outline: none
}


.select .multi-label {
    display: inline-block;
    text-wrap: none;
    margin: 4px;
    padding: 4px;
    padding-left: 10px;
    background-color: var(--light-grey);
    border-radius: 12px;
    border-width: 2px;
    border-color: var(--black);
    font-weight: 300;
}

.select .multi-label img {
    vertical-align: middle;
}
.select .multi-label .dropdown-cross {
    cursor: pointer;
    width: 10px;
    margin: 4px;
}

.select .greyed {
    color: var(--shading-grey);
    font-weight: 300;
}


/** Dropdown content */

.dropdown ul {
    position: absolute;

    border: 1px solid var(--light-grey);
    margin-top: -2px;
    padding-left: 0;
    background-color: var(--white);
    list-style-type: none;
    height: auto;
    max-height: 268px;
    z-index: 1;
    overflow-y: scroll;
}

.dropdown:not(.flipped) ul {
    border-top-color: transparent;
    border-radius: 0 0 12px 12px;
}

.dropdown.flipped ul {
    bottom: 100%;
    border-top-color: var(--light-grey);
    border-bottom-color: transparent;
    border-radius: 12px 12px 0 0;
    margin-bottom: 0;
}

.dropdown ul li {
    padding: 10px 20px;
    cursor: pointer;
    display: block;
    overflow: hidden;
}

.dropdown ul .no-options {
    padding: 10px 20px;
}

.dropdown img.icon {
    vertical-align: middle;
    padding-right: 4px;
    width: 26px;
}

.dropdown li.icon {
    padding-left: 10px;
}



.dropdown .small {
    font-weight: 400;
    font-size: 12px;
}

.dropdown ul li:hover {
    background-color: var(--lighter-grey);
}

.multi-placeholder {
    display: inline-block;
    margin: 10px 4px;
}

/** For multi form */
.dropdown .select.multi {
    padding: 4px;
    min-height: 52px;
    /* Space for indicator */
    padding-right: 42px;
}
