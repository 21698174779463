.editor {
    background: var(--white);
    border: 1px solid var(--light-grey);
    font-size: 14px;
    padding: 15px;
}

.editor-controls {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--light-grey);
    margin-bottom: 16px;
}

.editor-button {
    color: var(--grey);
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.active {
    color: var(--link-blue);
}

/** Link popover */
.editor-popover {
    display: none;
    position: absolute;
    z-index: 2;
    transform: translateX(-112px);
}

.editor-popover input {
    width: 256px;
}

.editor-popover.active {
    display: block;
    background: var(--white);
    border: 1px solid var(--shading-grey);
    padding: 8px;
    border-radius: 8px;
}
.editor-popover.active:after, .editor-popover.active:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.editor-popover.active:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--white);
    border-width: 4px;
    margin-left: -4px;
}
.editor-popover.active:before {
    border-color: rgba(255, 51, 34, 0);
    border-bottom-color: var(--shading-grey);
    border-width: 5px;
    margin-left: -5px;
}

/** Custom styling */
.editor-link > span > span {
    color: var(--link-blue)!important;
}

.editor-link:hover {
    text-decoration: underline;
}

.blockquote {
    border-left: 5px solid var(--light-grey);
    color: var(--grey);
    font-weight: 300;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}


/** Fixed small input field bug */
.DraftEditor-root {
    width: 100%;
}
