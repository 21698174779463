.category-filter:not(.selected) * {
    cursor: pointer;
}

.category-filter:not(.selected) :hover {
    background: var(--extra-light);
}

.category-filter.selected .category-item {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--selected-category);
}

.category-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    padding: 6px;
    padding-right: 12px;
    border-radius: 6px;

    width: fit-content;
}

.category-item img {
    width: 20px;
    height: 20px;
    padding-right: 14px;
}

.category-item .title {
    color: var(--shading-grey);
    font-weight: 600;
    font-size: 15px;
    line-height: 130.7%;
    letter-spacing: -0.02em;
    color: #606060;
    white-space: nowrap;
}

.category-filter.selected .title {
    color: var(--selected-category);
    font-weight: bold;
    line-height: 20px;
}
