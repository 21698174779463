.alert {
    border-radius: 8px;
    border: solid 1px;
    padding: 8px;
}

.alert .message {
    text-align: start;
}

/** Error */
.alert.error, .alert > .error {
    color: var(--error-text);
    background-color: var(--error-body);
    border-color: var(--error-border);
}

.alert.error .message {
    color: var(--error-text);
}

/** Success */
.alert.success, .alert > .success {
    color: var(--success-text);
    background-color: var(--success-body);
    border-color: var(--success-border);
}

.alert.success .message {
    color: var(--success-text);
}

/** Info */
.alert.info, .alert > .info {
    color: var(--info-text);
    background-color: var(--info-body);
    border-color: var(--info-border);
}

.alert.info .message {
    color: var(--info-text);
}





