.score-detail-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.score-detail table {
    margin-top: 16px;
    border-collapse: collapse;
}

.score-detail tr {
    vertical-align: middle;
    font-weight: 300;
    color: var(--grey);
    padding: 18px;
}

/* Add additional padding to question cell*/
/*.score-detail tr td:first-child {*/
/*    padding-right: 50px;*/
/*}*/

.score-detail .question-heading {
    text-align: left;
}

.score-detail tr td .circle-padding {
    /*margin-left: 16px;*/
    /*margin-right: 16px;*/
    margin: 16px 24px;
}

.score-detail tbody tr td {
    /*padding: 18px;*/
    font-weight: 300;
}
