.toggle {
    display: flex;
    flex-wrap: nowrap;
    margin: -4px;
}

.toggle-tab {
    cursor: pointer;
    margin: 4px;
    padding: 8px 18px;
    text-align: center;
    border-radius: 12px;
    /* border: 1px solid var(--shading-grey); */
    background-color: transparent;
    font-size: 16px;
    color: var(--grey);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.toggle-tab:hover {
    background-color: var(--extra-light);
    color: var(--link-blue);
}

.toggle-tab.selected {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #FF7E78;
    color: var(--selected-category);
    font-weight: bold;
}

.toggle-info-icon {
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggle-info-popup {
    text-align: left;
    font-weight: normal;
    color: var(--text-black);
    background-color: var(--extra-light);

    position: absolute;
    z-index: 1000;
    left: 90%;
    top: 0;

    width: 500px;
    padding: 34px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.toggle-info-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
}


/** mobile view */


/*@media only screen and (max-width: 702px) {*/
/*    .toggle {*/
/*        flex-wrap: wrap;*/
/*    }*/

/*    .toggle > div {*/
/*        width: 100%;*/
/*    }*/
/*}*/
