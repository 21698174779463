.question-form {
    margin-bottom: 32px;
    height: 100%;
}

.question-form form {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.question-form .page {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 16px;
    padding-bottom: 16px;
}

.question-group {
    margin: 8px 8px 32px 8px;
}


.subsection-title {
    display: flex;
}

.subsection-title img {
    padding-left: 6px;
}

.subsection-title.question-group-title {
    padding-top: 48px;
}

.question-form .evaluation-heading {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
    z-index: 5;
    margin: 0 16px;
    padding-bottom: 8px;
    padding-top: 8px;
}

.question-form .evaluation-heading > h3 {
    margin-top: 0;
}


/** Title Component */
.question-form .title-component {
    display:flex;
    align-items:center;
    margin-top: 16px;
}

.question-form .title-component p {
    display: inline-block;
    margin: auto 0;
    padding-right: 6px;
}

.question-title-component-image {
    width: 14px;
    padding-top: 6px;
    cursor: pointer;
}

.evaluation-modal {
    margin: 48px 8px;
    color: var(--grey);
}

.evaluation-modal p {
    font-size: 16px;
}

.evaluation-modal ul {
    padding-left: 24px;
}

.evaluation-modal li {
    font-weight: 300;
    font-size: 16px;
    margin: 8px 0;
}

.evaluation-modal h5 {
    color: var(--grey);
    margin-bottom: 0;
}

