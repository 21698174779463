.auth-form {
    width: 272px;
    margin: auto;
    display: block;
}

.auth-form h2 {
    padding-top: 36px;
}

.auth-form h3 {
    color: var(--black);
    font-size: 18px;
    font-weight: 800;

    /*To space correctly to style guide*/
    margin-bottom: 64px;
}

.auth-form .submit {
    margin-top: 64px;
    margin-bottom: 16px;
    margin-left: 0;
    margin-right: 4px;
}

.auth-form p {
    text-align: center;
    margin: 4px;
    font-size: 12px;
    font-weight: 400;
}

.auth-form .links {
    font-size: 12px;
    font-weight: 600;
}
