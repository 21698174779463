.home {
    margin: auto;
}

.home-layout {
    margin-top: 48px;
    display: grid;
    grid-template-areas:
        "cards cards cards"
        "main-content main-content laws";
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 25px;
    row-gap: 50px;
}

.home-content {
    grid-area: main-content;
    display: flex;
    flex-direction: column;
    margin-right: 75px;
    /* min-width: 625px; */
}

.law-highlights {
    grid-area: laws;
}

.home-heavy-content p {
    font-weight: 600;
    line-height: 150%;
}


@media only screen and (max-width: 1200px) {
    .home-layout {
        grid-template-areas:
            "cards cards cards"
            "main-content main-content main-content";
    }

    .home-content {
        margin-right: 0;
    }

    .home .law-highlights {
        display: none;
    }
}

@media only screen and (max-width: 460px) {
    .home-layout {
        margin-top: 8px;
    }

    .home .video-player-wrapper {
        order: -1;
    }
}
