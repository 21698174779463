.spinner {
    width: 10px;
    height: 10px;
    border: 2px solid var(--lighter-grey);
    border-radius: 50%;
    border-top-color: var(--shading-grey);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
