
.score-popup {
    position: absolute;
    top: 50%;
    white-space: nowrap;
    padding: 22px 33px 30px;

    color: var(--text-black);
    background-color: var(--extra-light);
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
}

.score-popup-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
}

.score-popup-item {
    padding-left: 25px;
    margin-top: 2px;
    position: relative;
    opacity: 0.5;
}

.score-popup-item img {
    display: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.score-popup-item-current img {
    display: block;
}

.score-popup-item-current {
    opacity: 1;
    font-weight: bold;
    color: var(--score-colour);
}


.score-popup-meeting {
    --score-colour: var(--scoring-meeting);
}

.score-popup-mostly {
    --score-colour: var(--scoring-mostly);
}

.score-popup-partially {
    --score-colour: var(--scoring-partially);
}

.score-popup-not {
    --score-colour: var(--scoring-not);
}

.score-popup-disregard {
    --score-colour: var(--scoring-disregard);
}

/**
 * Breakpoints to keep popup on screen when various elements rearrange on the various pages
 */

@media only screen and (min-width: 1086px) {
    .score-popup-left-large-screen {
        right: 50%;
    }

    .score-popup-right-large-screen {
        left: 50%;
    }

    .score-popup-centered-large-screen {
        right: 50%;
        transform: translateX(50%);
    }
}

@media only screen and (max-width: 1086px) and (min-width: 574px) {
    .score-popup-left-medium-screen {
        right: 50%;
    }

    .score-popup-right-medium-screen {
        left: 50%;
    }

    .score-popup-centered-medium-screen {
        right: 50%;
        transform: translateX(50%);
    }
}

@media only screen and (max-width: 574px) {
    .score-popup-left-small-screen {
        right: 50%;
    }

    .score-popup-right-small-screen {
        left: 50%;
    }

    .score-popup-centered-small-screen {
        right: 50%;
        transform: translateX(50%);
    }
}
