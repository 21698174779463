.search-bar {
    position: relative;
    /* width: 100%; */
    flex-grow: 1;
}

.search-bar input {
    font-weight: 600;
    font-size: 18px;
    padding: 4px 26px 4px 10px;
    width: 100%;
    outline: none;
    color: var(--grey);
    border-color: var(--light-grey);
    transition: 0.2s;
    border-width: 1px;
    border-radius: 12px;
    height: 38px;
    border-style: solid;
    box-sizing: border-box;
    min-width: 100px;
}

.search-bar input:focus {
    border-color: var(--shading-grey);
    transition: 0.2s;
}

.search-bar input::placeholder {
    color: var(--shading-grey);
}

.search-bar .indicator {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
}

.search-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.search-wrapper .filter {
    padding-right: 16px;
    cursor: pointer;
}

@media only screen and (min-width: 932px) {
    .search-wrapper .filter {
        display: none;
    }
}
