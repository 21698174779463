.blog-comments {
    margin-top: 12px;
}

/** View comment */
.blog-comments .view-comment {
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 12px;
}

.blog-comments .spacer {
    background-color: var(--lighter-grey);
    width: 100%;
    height: 1px;
    margin: 8px 0;
}

.blog-comments .view-comment:hover {
    background-color: var(--lighter-grey);
}
.blog-comments .view-comment > h4 {
    margin-top: 0;
    margin-bottom: 2px;
}.blog-comments .view-comment > p {
     margin-top: 2px;
     margin-bottom: 0;
     font-style: italic;
}
/** END View comment */

/** Edit Comment */
/** Add Button */
.add-comment-button-wrapper {
    padding: 8px 0;
    position: relative;
    width: 100%;
    height: 16px; /* TODO: right aligning without float */
}

.add-comment-button {
    float: right;
    margin-right: 8px;
    font-weight: 600;
    color: var(--link-blue);
    cursor: pointer;
}

.blog-comments .add-comment-button > img {
    padding-right: 4px;
    width: 16px;
    vertical-align: -10%;
}
/** END Add Button */

.blog-comments .comment-cancel {
    width: 14px;
    float: right;
    padding-right: 4px;
    cursor: pointer;
}

.blog-comments .edit-comment {
    background-color: white;
    padding: 12px 16px 16px 16px;
    border-radius: 12px;
    margin: 8px 0;
}

.blog-comments .edit-comment > * {
    margin: 4px 0;
}

/** END Edit Comment */
