.tooltip {
    position: absolute;
    display: block;
    padding-left: 80%;
    z-index: 1;
    bottom: 0;
    padding-bottom: 55%;
}

.tooltip.flip {
    bottom: unset;
    padding-bottom: 0;
}

.tooltip .wrapper {
    background-color: var(--grey);
    padding: 10px;
    border-radius: 12px;
    position: relative;
}

.tooltip .wrapper.flip {
    margin-left: 16px;
}

.tooltip img.flip {
    transform: scaleX(-1) rotate(-67deg);
    left: -12px;
    top: 25px;
}

.tooltip img {
    position: absolute;
    bottom: -10px;
    left: 2px;
    transform: scaleX(-1);
}

.tooltip span {
    color: var(--lighter-grey);
    font-weight: 600;
}

.tooltip .tooltip-breakdown {
    display: inline-flex;
}

.tooltip .tooltip-breakdown span {
    padding-left: 8px;
    white-space: nowrap;
}

.tooltip .tooltip-evaluator {
    text-align: left;
    padding: 8px;
    width: 400px;
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-color: var(--light-grey) transparent;
    font-size: 14px;
}

.tooltip .tooltip-evaluator h3 {
    color: var(--white);
    font-weight: 600;
}

.tooltip .tooltip-evaluator p {
    color: var(--white);
    font-weight: 300;
}

.tooltip .tooltip-evaluator ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 8px;
    position: relative;
}

.tooltip .tooltip-evaluator li:before {
    content: '>';
    position: absolute;
    left: 0;
}

.tooltip .tooltip-evaluator li {
    color: var(--white);
    font-weight: 300;
    padding-left: 4px;
}

.tooltip .tooltip-evaluator-count {
    color: var(--white);
    white-space: nowrap;
    text-align: left;
    font-size: 14px;
}
