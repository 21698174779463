.modal {
    position: fixed; /* Stay in place */
    z-index: 20; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.8); /* Black w/ opacity */
    overflow: hidden;
}

.modal-cross {
    float: right;
    position: sticky;
    top: 0;
    right: 0;
    cursor: pointer;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 12px;
    position: fixed;
    float: right;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;


    /*defaults for mobile*/
    width: 100%;
    height: 100%;

    overflow: auto;
}

.header-space {
    margin-top: 96px;
}

.links-space {
    margin-top: 96px;
}

.input-space {
    margin-top: 32px;
    margin-bottom: 32px;
}

.warning-space {
    margin-top: 32px;
}

@media only screen and (min-width: 480px) {
    .modal-content {
        -webkit-box-shadow: 0 0 16px 2px rgba(0,0,0,0.14);
        -moz-box-shadow: 0 0 16px 2px rgba(0,0,0,0.14);
        box-shadow: 0 0 16px 2px rgba(0,0,0,0.14);
        max-height: 90vh;
    }

    .modal-content.small {
        width: 480px;
        height: auto;
    }

    .modal-content.medium {
        width: 50%;
        min-width: 480px;
        height: auto;
    }

    .modal-content.large {
        width: 80%;
        height: auto;
    }
}
