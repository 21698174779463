.breakdown p {
    font-weight: 300;
}

.breakdown a {
    font-weight: 600;
}

.breakdown .icon {
    width: 18px;
    padding-right: 16px;
    vertical-align: middle;
}

.evaluator-item {
    padding: 10px;
    border-radius: 12px;
}

.evaluator-item .evaluator-name {
    padding-left: 36px;
    padding-top: 5px;
    font-style: italic;
    font-size: 13px;
    margin: 0;
}

.evaluator-score {
    margin-top: 10px;
}
